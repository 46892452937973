import React from "react";

/**
 * Html block
 *
 * @param data
 * @return {JSX.Element}
 * @constructor
 */
const Html = ({ data }) => {
  return <div dangerouslySetInnerHTML={{ __html: data.html }} />;
};

export default Html;
