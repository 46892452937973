import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./Map.module.scss";
import imgTriangle from "../../images/black-triangle.svg";

/**
 * Map block
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const Map = ({ data }) => {
  let containerColor = "dark";
  let textColor = "light";

  if ("Light" === data.backgroundColor) {
    containerColor = "light";
    textColor = "dark";
  }

  let triangleContent;
  let titleClasses = `${styles.title}`;
  let titleContainerPosition;

  if (data.textPosition === `Middle`) {
    triangleContent = (
      <div className={styles.triangle}>
        <img alt="Decorative triangle" src={imgTriangle} />
      </div>
    );
    titleContainerPosition = styles.titleContainerMiddle;
    titleClasses += ` h1`;
  } else {
    titleContainerPosition = styles.titleContainerRight;
    titleClasses += ` h1-sm`;
  }

  return (
    <article className={`${styles.wrapper} bg-${containerColor}`}>
      {data.imageMobile?.data && (
        <figure
          className={`mb-0 d-lg-none opacity-50 ${styles.mobileImageWrapper}`}
        >
          <img
            alt={data.imageMobile.data.attributes.alternativeText}
            src={data.imageMobile.data.attributes.url}
            className={`${styles.mobileImage}`}
          />
        </figure>
      )}
      {data.imageDesktop?.data && (
        <figure className="mb-0 d-none d-lg-block opacity-50">
          <img
            alt={data.imageDesktop.data.attributes.alternativeText}
            src={data.imageDesktop.data.attributes.url}
            className="img-fluid w-100"
          />
        </figure>
      )}
      <div className={`${styles.titleContainer} ${titleContainerPosition}`}>
        <Container>
          <Row>
            <Col>
              <h2 className={titleClasses}>
                {data.title.trim() ? (
                  <span className={`text-outline text-outline--${textColor}`}>
                    {data.title}
                  </span>
                ) : null}

                {data.title.trim() && data.subtitle ? <br /> : null}

                {data.subtitle ? (
                  <span className={`text-${textColor}`}>{data.subtitle}</span>
                ) : null}
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      {triangleContent}
    </article>
  );
};

export default Map;
