import React from "react";
import ReactMarkdown from "react-markdown";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./Drink.module.scss";

/**
 * Drink block
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const Drink = ({ data }) => {
  let containerColor = "dark";
  let textColor = "light";

  if ("Light" === data.backgroundColor) {
    containerColor = "light";
    textColor = "dark";
  }

  let flushLayout = false;

  if (!data.recipe) {
    flushLayout = true;
  }

  const displayContent = () => {
    if (data.recipe.length > 0) {
      return (
        <div
          className={`${styles.ingredients} bg-${textColor} text-${containerColor}`}
        >
          <h3 className={styles.ingredients__title}>
            Ingredients{" "}
            <span
              className={`${styles.ingredients__titleBorder} bg-${containerColor}`}
            />
          </h3>
          <ol className={styles.ingredients__list}>
            {data.recipe.map((item, index) => {
              return (
                <li className={styles.ingredients__listItem} key={index}>
                  <p className="mb-0">
                    <strong>{item.quantity}</strong>
                  </p>
                  <p className="mb-0">{item.ingredient}</p>
                </li>
              );
            })}
          </ol>
        </div>
      );
    } else {
      if (data.imageMobile?.data || data.imageDesktop?.data) {
        return (
          <>
            {data.imageMobile?.data && (
              <figure className="mb-0 d-lg-none">
                <img
                  alt={data.imageMobile.data.attributes.alternativeText}
                  src={data.imageMobile.data.attributes.url}
                  className="img-fluid w-100"
                />
              </figure>
            )}
            {data.imageDesktop?.data && (
              <figure className="mb-0 d-none d-lg-block">
                <img
                  alt={data.imageDesktop.data.attributes.alternativeText}
                  src={data.imageDesktop.data.attributes.url}
                  className="img-fluid w-100"
                />
              </figure>
            )}
          </>
        );
      } else {
        return null;
      }
    }
  };

  return (
    <article
      className={`${
        styles.wrapper
      } bg-${containerColor} text-center text-lg-start ${
        flushLayout ? `py-lg-0` : ``
      }`}
    >
      <Container>
        <Row className="align-items-lg-center">
          <Col xs={12} lg={6}>
            <h2 className={`h3 ${styles.title} text-${textColor}`}>
              {data.title}{" "}
              <span className={`${styles.title__border} bg-${textColor}`} />
            </h2>
            {data.body ? (
              <ReactMarkdown
                className={`${styles.paragraph} text-${textColor}`}
              >
                {data.body}
              </ReactMarkdown>
            ) : null}
          </Col>
          <Col xs={12} lg={6}>
            {displayContent()}
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default Drink;
