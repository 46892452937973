import React from "react";
import ReactMarkdown from "react-markdown";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./Video.module.scss";
import playIcon from "../../icons/play.svg";

/**
 * Video
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const Video = ({ data }) => {
  let containerColor = "dark";
  let textColor = "light";

  if ("Light" === data.backgroundColor) {
    containerColor = "light";
    textColor = "dark";
  }

  const videoId = `Video_${data.id}`;

  const playVideo = evt => {
    const button = evt.target;
    const video = document.getElementById(videoId);
    button.style.display = `none`;
    video.play();
    video.setAttribute(`controls`, `controls`);
  };

  return (
    <article
      className={`${styles.wrapper} bg-${containerColor} text-center text-lg-start`}
    >
      <Container>
        <Row className="align-items-lg-center">
          <Col xs={12} lg={5} xl={4}>
            <h2 className={`${styles.title} h3 text-${textColor}`}>
              {data.title}
            </h2>
            {data.body ? (
              <ReactMarkdown
                className={`${styles.paragraph} text-${textColor}`}
              >
                {data.body}
              </ReactMarkdown>
            ) : null}
          </Col>
          {data.video?.data && (
            <Col xs={12} lg={6} xl={7} className="offset-xl-1">
              <div className="video-container position-relative">
                <button
                  className={styles.playButton}
                  type="button"
                  aria-label="Play video"
                  onClick={playVideo}
                >
                  <img
                    src={playIcon}
                    alt="Play icon"
                    className="img-fluid w-100"
                  />
                </button>
                <video
                  className={styles.video}
                  id={videoId}
                  preload="none"
                  poster={data.poster?.data?.attributes?.url}
                  controlsList="nodownload noremoteplayback nofullscreen"
                >
                  <source
                    src={data.video.data.attributes.url}
                    type={data.video.data.attributes.mime}
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </article>
  );
};

export default Video;
