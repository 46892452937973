import React from "react";
import { Container, Row, Col, Ratio } from "react-bootstrap";

import * as styles from "./Hero.module.scss";
import cx from "classnames";
import CtaContainer from "../shared/CtaContainer";

/**
 * Hero block
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const Hero = ({ data }) => {
  const isLight = data.backgroundColor === "light";

  const backgroundColor = isLight ? "light" : "dark";
  const foregroundColor = isLight ? "dark" : "light";

  return (
    <article
      className={cx(
        `bg-${backgroundColor} position-relative`,
        styles.container
      )}
    >
      {data.backgroundImageMobile?.data && (
        <Ratio className="d-lg-none mb-5" aspectRatio={255 / 375}>
          <div
            className={styles.desktopMobile}
            style={{
              backgroundImage: `url(${data.backgroundImageMobile.data.attributes.url})`,
            }}
          />
        </Ratio>
      )}
      {data.backgroundImageDesktop?.data && (
        <div
          className={cx("d-none d-lg-block", styles.desktopBackground)}
          style={{
            backgroundImage: `url(${data.backgroundImageDesktop.data.attributes.url})`,
          }}
        />
      )}

      {Boolean(parseFloat(data.imageOverlay || 0)) && (
        <div
          className={cx(styles.imageOverlay, "d-none d-lg-block")}
          style={{ opacity: data.imageOverlay }}
        ></div>
      )}
      <Container className="position-relative">
        <Row>
          <Col xs={12} lg={8} className="pb-5">
            <h1 className="mb-0 text-center text-lg-start">
              {data.title && (
                <span
                  className={`text-outline text-outline--${foregroundColor}`}
                >
                  {data.title}
                </span>
              )}
              {Boolean(data.title && data.subtitle) && <br />}
              {data.subtitle && (
                <span className={`text-${foregroundColor}`}>
                  {data.subtitle}
                </span>
              )}
            </h1>
            {Boolean(data.bodyText) && (
              <p
                className={cx(
                  styles.bodyText,
                  "text-center text-lg-start mt-2 mb-0",
                  data.bodyDisplayOn === "desktop" && "d-none d-lg-block",
                  data.bodyDisplayOn === "mobile" && "d-lg-none",
                  "text-" + foregroundColor
                )}
              >
                {data.bodyText}
              </p>
            )}
            <CtaContainer
              className="mt-2 mt-lg-4"
              data={data}
              foregroundColor={foregroundColor}
            />
          </Col>
        </Row>
      </Container>
    </article>
  );

  // return (
  //   <Wrapper>
  //     <Ratio className="d-lg-none" aspectRatio={255 / 375}>
  //       <Cover image={data.Mobile} />
  //     </Ratio>
  //     <Ratio className="d-none d-lg-block" aspectRatio={9 / 16}>
  //       <Cover image={data.Desktop} />
  //     </Ratio>
  //     <TitleContainer
  //       className={`bg-${backgroundColor} position-${data.Position.toLowerCase()}`}
  //     >
  //       <Container fluid={true}>
  //         <Row>
  //           <Col xs={12}>
  //             <Title className={data.Position === `Left` ? `h1` : `h1-sm`}>
  //               <TitleStroke
  //                 className={`text-outline text-outline--${foregroundColor}`}
  //               >
  //                 {data.Title}
  //               </TitleStroke>
  //               <br />
  //               <TitleSolid className={`text-${foregroundColor}`}>
  //                 {data.Subtitle}
  //               </TitleSolid>
  //             </Title>
  //           </Col>
  //           <Col xs={12}>{textHtml}</Col>
  //           <Col xs={12}>{CtaHtml}</Col>
  //         </Row>
  //       </Container>
  //     </TitleContainer>
  //   </Wrapper>
  // );
};

export default Hero;
