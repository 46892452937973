import React from "react";
import _ from "lodash";
import cx from "classnames";

import * as styles from "./MessageFeed.module.scss";
import NubIncoming from "../../images/duskNubGray.png";
import NubOutgoing from "../../images/duskNubBlue.png";
import Caret from "../../images/caret.svg";
import CtaContainer from "../shared/CtaContainer";

const MessageFeed = ({ data }) => {
  const messageChunks = _.chunk(data.messageItems, 3);
  return (
    <article className="bg-light py-5">
      <div className="container">
        <div className="d-flex flex-column align-items-center">
          {data.logo.data && (
            <img
              alt={data.title}
              className={cx("mb-3", styles.logo)}
              src={data.logo.data.attributes.url}
            />
          )}
          <div className="d-flex flex-row align-items-center justify-content-center mb-3">
            <h3 className={cx("text-dark mb-0", styles.title)}>{data.title}</h3>
            <img
              alt={data.title}
              className={cx("ms-2", styles.caret)}
              src={Caret}
            />
          </div>
          <p className="text-dark text-center fw-bold d-lg-none">
            Submit your own BFF story or let us know if one of these is you!
          </p>
          <div className="row justify-content-center">
            {messageChunks.map((chunk, chunkIdx) => (
              <div
                className={cx(
                  "col-12 col-md-6 col-lg-4 px-lg-4",
                  chunkIdx > 0 && "mt-3 mt-md-0"
                )}
              >
                {chunk.map((message, idx) => (
                  <div className={cx(styles.messageBlock, idx > 0 && "mt-3")}>
                    <p className={cx(styles.caption, "mb-1")}>
                      {message.caption}
                    </p>

                    {message.image.data && (
                      <img
                        alt={message.caption}
                        className={cx(styles.img, "mb-1")}
                        src={message.image.data.attributes.url}
                      />
                    )}

                    <div
                      className={cx(
                        styles.bubble,
                        message.messageType === "Outgoing"
                          ? styles.outgoing
                          : styles.incoming
                      )}
                    >
                      <p>{message.message}</p>
                      <img
                        alt="nub"
                        className={styles.nub}
                        src={
                          message.messageType === "Outgoing"
                            ? NubOutgoing
                            : NubIncoming
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <CtaContainer className="mt-3 mt-lg-5" data={data} />
        </div>
      </div>
    </article>
  );
};

export default MessageFeed;
