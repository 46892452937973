import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./Download.module.scss";
import appStore from "../../images/app-store.svg";
import googlePlay from "../../images/google-play.svg";
import cx from "classnames";
/**
 * Download block
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const Download = ({ data }) => {
  let containerColor = "dark";
  let textColor = "light";

  if ("light" === data.backgroundColor) {
    containerColor = "light";
    textColor = "dark";
  }

  const badges = [
    { image: appStore, alt: "App Store badge", url: data.iosUrl },
    { image: googlePlay, alt: "Google Play badge", url: data.androidUrl },
  ];

  return (
    <article
      className={`${styles.wrapper} bg-${containerColor} text-center text-lg-start`}
    >
      <Container>
        <Row className="align-items-lg-center">
          <Col xs={12} lg={5} className="order-lg-2">
            <h2 className={`h3 text-${textColor}`}>{data.title}</h2>
            {data.subtitle ? (
              <p className={`${styles.subtitle} font-medium text-${textColor}`}>
                {data.subtitle}
              </p>
            ) : (
              ``
            )}
            <ul className={styles.list}>
              {badges.map((badge, index) => {
                return (
                  <li className={styles.list__item} key={index}>
                    <a
                      href={badge.url}
                      aria-label="Download the app"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <figure className="mb-0">
                        <img
                          className="img-fluid"
                          src={badge.image}
                          alt={badge.alt}
                        />
                      </figure>
                    </a>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col xs={12} lg={7} className="order-lg-1 mt-4 mt-lg-0">
            {data.imageMobile?.data && (
              <img
                alt={data.imageMobile.data.attributes.alternativeText}
                src={data.imageMobile.data.attributes.url}
                className={cx("d-lg-none mx-auto", styles.image)}
              />
            )}

            {data.imageDesktop?.data && (
              <img
                alt={data.imageDesktop.data.attributes.alternativeText}
                src={data.imageDesktop.data.attributes.url}
                className={cx("mx-auto d-none d-lg-block", styles.image)}
              />
            )}
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default Download;
