import React from "react";
import ReactMarkdown from "react-markdown";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./ThreeImages.module.scss";

/**
 * Three Images block
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const ThreeImages = ({ data }) => {
  let containerColor = "dark";
  let textColor = "light";

  if ("Light" === data.backgroundColor) {
    containerColor = "light";
    textColor = "dark";
  }

  return (
    <article className={`${styles.wrapper} bg-${containerColor} text-center`}>
      <Container>
        <Row>
          <Col xs={12}>
            <h2 className={`h3 text-${textColor}`}>{data.title}</h2>
            {data.body ? (
              <ReactMarkdown
                className={`${styles.paragraph} text-${textColor}`}
              >
                {data.body}
              </ReactMarkdown>
            ) : null}
          </Col>
        </Row>
        <Row className="justify-content-lg-center gx-lg-6">
          <Col>
            <Row className="flex-row gx-lg-6 justify-content-center">
              {(data.images?.data || []).map((image, idx) => {
                return (
                  <Col xs={4} key={image.attributes.url}>
                    <img
                      alt=""
                      className={styles.item__image}
                      src={image.attributes.url}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default ThreeImages;
