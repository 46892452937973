import React from "react";
import cx from "classnames";
import * as styles from "./HowItWorks.module.scss";
import { Container, Row, Col } from "react-bootstrap";

import PhoneFramedContent from "../shared/PhoneFramedContent";

const HowItWorks = ({ data }) => {
  const blockColor = data.backgroundColor;
  const isLight = blockColor !== "dark";

  const foregroundColor = isLight ? "dark" : "light";

  return (
    <article
      className={cx(
        { light: "bg-light", dark: "bg-dark", medium: styles.bgGrey }[
          blockColor
        ],
        "pt-5"
      )}
    >
      <Container>
        <Row className="flex-row align-items-stretch">
          <Col
            xs={12}
            lg={6}
            className={cx(
              "d-flex flex-column align-items-center order-2",
              data.desktopMediaAlign === "left" && "order-lg-1"
            )}
          >
            <PhoneFramedContent
              backgroundStyle={data.backgroundColor}
              className="d-lg-none"
              frameType={data.mobileMediaType}
              mediaAttributes={
                data[`${data.mobileMediaType}Media`]?.data?.attributes
              }
            />
            <PhoneFramedContent
              backgroundStyle={data.backgroundColor}
              className="d-none d-lg-block"
              frameType={data.desktopMediaType}
              mediaAttributes={
                data[`${data.desktopMediaType}Media`]?.data?.attributes
              }
            />
          </Col>
          <Col
            xs={12}
            lg={6}
            className={cx(
              "pb-5 order-1 d-flex flex-column justify-content-center",
              data.desktopMediaAlign === "left" && "order-lg-2"
            )}
          >
            {data.title && (
              <h2
                className={cx(
                  `h3 text-center text-lg-start text-${foregroundColor}`
                )}
              >
                {data.title}
              </h2>
            )}

            {data.listItems && data.listItems.length > 0 && (
              <ul className={cx("mt-4", styles.listItems)}>
                {data.listItems.map((listItem, idx) => (
                  <li
                    className={cx(
                      "d-flex flex-column flex-lg-row align-items-center align-items-lg-start mx-0",
                      idx > 0 && "mt-3"
                    )}
                    key={idx}
                  >
                    {listItem.icon.data && (
                      <img
                        alt={listItem.title}
                        className={cx(
                          styles.listItemIcon,
                          "me-lg-3 mb-3 mb-lg-0"
                        )}
                        src={listItem.icon.data.attributes.url}
                      />
                    )}
                    <div className="d-flex flex-column align-items-center align-items-lg-start">
                      {Boolean(listItem.title) && (
                        <h4
                          className={cx(
                            `text-${foregroundColor}`,
                            "text-center text-lg-start mb-0",
                            styles.listItemTitle
                          )}
                        >
                          {listItem.title}
                        </h4>
                      )}
                      {Boolean(listItem.body) && (
                        <p
                          className={cx(
                            `text-${foregroundColor}`,
                            "text-center text-lg-start mb-0",
                            styles.listItemBody,
                            Boolean(listItem.title) && "mt-1"
                          )}
                        >
                          {listItem.body}
                        </p>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            )}

            {data.cta && (
              <a
                className={cx(
                  "btn btn-md w-100",
                  data.cta.style === "Solid"
                    ? `btn-${foregroundColor}`
                    : `btn-outline-${foregroundColor}`
                )}
                href={data.cta.url}
              >
                {data.cta.text}
              </a>
            )}
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default HowItWorks;
