import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/layout";
import BlocksRenderer from "../components/blocks-renderer/blocks-renderer";
import "../scss/styles.scss";

const Page = ({ pageContext: { page } }) => {
  // Title Tag
  const pageTitle = ["DUSK", page.title].filter(Boolean).join(" - ");
  // Metadata
  const metaTitle = ["DUSK", page.seo?.title].filter(Boolean).join(" - ");
  const metaDescription = page.seo?.description || "Get rewarded for going out";
  const metaImage =
    page.seo?.imageUrl || "https://static.dusk.app/website/share-img.png";
  const url = typeof window !== `undefined` ? window.location.href : ``;

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: `en` }}>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <link rel="canonical" href={url} />
        <link rel={`description`} content={metaDescription} />
        <meta property="og:title" content={`${metaTitle}`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:site_name" content={"DUSK"} />

        {/* Meta Pixel Code */}
        {Boolean(page.metaPixelId) && (
          <script>
            {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${page.metaPixelId}');
          fbq('track', 'PageView');
          `}
          </script>
        )}
        {Boolean(page.metaPixelId) && (
          <noscript>
            {`<img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=${page.metaPixelId}&ev=PageView&noscript=1"
            />`}
          </noscript>
        )}
      </Helmet>
      <BlocksRenderer blocks={page.blocks || []} />
    </Layout>
  );
};

export default Page;
