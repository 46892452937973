import React from "react";
import ReactMarkdown from "react-markdown";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./AppPreview.module.scss";

import PhoneFramedContent from "../shared/PhoneFramedContent";

/**
 * App Preview block
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const AppPreview = ({ data }) => {
  let containerColor = "dark";
  let textColor = "light";

  if ("light" === data.backgroundColor) {
    containerColor = "light";
    textColor = "dark";
  }

  return (
    <article
      className={`${styles.wrapper} bg-${containerColor} text-center text-lg-start`}
    >
      <Container>
        <Row className="align-items-lg-center">
          <Col xs={12} lg={5} xl={4} className="offset-lg-1">
            <h2 className={`${styles.title} h3 text-${textColor}`}>
              {data.title}
            </h2>
            {data.groups.map((group, index) => {
              return (
                <ul className={styles.group} key={index}>
                  <figure className={styles.group__figure}>
                    <img
                      alt={group.image.data.attributes.alternativeText}
                      src={group.image.data.attributes.url}
                      className="img-fluid"
                    />
                  </figure>
                  <div className={styles.group__text}>
                    <h3 className={`${styles.group__title} text-${textColor}`}>
                      {group.title}
                    </h3>
                    {group.body ? (
                      <ReactMarkdown
                        className={`${styles.group__text} text-${textColor}`}
                      >
                        {group.body}
                      </ReactMarkdown>
                    ) : null}
                  </div>
                </ul>
              );
            })}
          </Col>
          {data.video?.data && (
            <Col
              xs={12}
              lg={6}
              className="offset-xl-1 d-flex flex-column align-items-center"
            >
              <PhoneFramedContent
                backgroundStyle={containerColor}
                mediaAttributes={data.video.data?.attributes}
                posterAttributes={data.poster.data?.attributes}
              />
            </Col>
          )}
        </Row>
      </Container>
    </article>
  );
};

export default AppPreview;
