import React from "react";
import ReactMarkdown from "react-markdown";
import { Container, Row, Col } from "react-bootstrap";

const RichText = ({ data }) => {
  const backgroundColor = data.backgroundColor === "Light" ? "light" : "dark";
  const foregroundColor = data.backgroundColor === "Light" ? "dark" : "light";

  return (
    <article className={`bg-${backgroundColor}`}>
      <Container className="py-5">
        <Row className="justify-content-lg-center">
          <Col lg={10} xl={8}>
            {data.title && (
              <h2 className={`h3 text-center text-${foregroundColor}`}>
                {data.title}
              </h2>
            )}
            {data.body && (
              <div className={`text-${foregroundColor}`}>
                <ReactMarkdown>{data.body}</ReactMarkdown>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default RichText;
