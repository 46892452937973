import React from "react";
import AppPreview from "../blocks/AppPreview";
import Bars from "../blocks/Bars";
import Download from "../blocks/Download";
import Drink from "../blocks/Drink";
import Grid from "../blocks/Grid";
import Hero from "../blocks/Hero";
import HowItWorks from "../blocks/HowItWorks";
import Html from "../blocks/Html";
import Map from "../blocks/Map";
import MessageFeed from "../blocks/MessageFeed";
import RichText from "../blocks/RichText";
import Text from "../blocks/Text";
import TextImage from "../blocks/TextImage";
import ThreeImages from "../blocks/ThreeImages";
import Video from "../blocks/Video";

const componentsMap = {
  "block.app-preview": AppPreview,
  "block.bars": Bars,
  "block.download": Download,
  "block.drink": Drink,
  "block.grid": Grid,
  "block.hero": Hero,
  "block.how-it-works": HowItWorks,
  "block.map": Map,
  "block.i-message-feed": MessageFeed,
  "block.rich-text": RichText,
  "block.text": Text,
  "block.text-and-image": TextImage,
  "block.three-images": ThreeImages,
  "block.video": Video,
  "block.html": Html,
};

const Block = ({ block }) => {
  const Component = componentsMap[block.type];

  if (!Component) {
    return null;
  }

  return <Component data={block.data} />;
};

const BlocksRenderer = ({ blocks }) => {
  return (
    <>
      {blocks.map((block, index) => {
        return <Block key={`${index}`} block={block} />;
      })}
    </>
  );
};

export default BlocksRenderer;
