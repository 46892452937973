import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./Bars.module.scss";
import locationIcon from "../../icons/location.svg";

/**
 * Bars block
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const Bars = ({ data }) => {
  let containerColor = "dark";

  if ("Light" === data.backgroundColor) {
    containerColor = "light";
  }

  return (
    <article className={`${styles.wrapper} bg-${containerColor}`}>
      <Container className="px-0 px-lg-4">
        <Row className="gx-0 gx-lg-7 justify-content-center">
          <Col xs={12} lg={10}>
            <Row className="gx-0 gx-lg-7">
              {data.bars.data.length > 0
                ? data.bars.data.map((bar, index) => {
                    return (
                      <Col xs={12} md={6} key={index}>
                        <div className={styles.item}>
                          <div
                            className={styles.item__image}
                            style={{
                              backgroundImage: `url(${bar.attributes.image.data.attributes.url})`,
                            }}
                          />
                          <div className={styles.item__overlay}>
                            <h4 className={styles.item__name}>
                              {bar.attributes.name}
                            </h4>
                            <div className={styles.item__location}>
                              <figure className={styles.item__icon}>
                                <img src={locationIcon} alt="Location" />
                              </figure>
                              <p className="mb-0">{bar.attributes.location}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                : null}
            </Row>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default Bars;
