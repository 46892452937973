// extracted by mini-css-extract-plugin
export var bubble = "MessageFeed-module--bubble--O+OUm";
export var caption = "MessageFeed-module--caption--QC1Ax";
export var caret = "MessageFeed-module--caret--blw4D";
export var img = "MessageFeed-module--img--SgG5h";
export var incoming = "MessageFeed-module--incoming--XEarl";
export var logo = "MessageFeed-module--logo--1Fx2b";
export var messageBlock = "MessageFeed-module--messageBlock--eOa8w";
export var nub = "MessageFeed-module--nub--3s4Y9";
export var outgoing = "MessageFeed-module--outgoing--uZlJb";
export var title = "MessageFeed-module--title--s9DDl";