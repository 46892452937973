import React from "react";
import * as styles from "./PhoneFramedContent.module.scss";
import cx from "classnames";

import iPhoneFrameBlackCutOff from "../../images/iPhoneFrameCutOffBlack.png";
import iPhoneFrameWhiteCutOff from "../../images/iPhoneFrameCutOffWhite.png";
import iPhoneFrameMediumCutOff from "../../images/iPhoneFrameCutOffMedium.png";
import iPhoneFrameMediumFull from "../../images/iPhoneFrameFullMedium.png";
import iPhoneFrameBlackFull from "../../images/iPhoneFrameFullBlack.png";
import iPhoneFrameWhiteFull from "../../images/iPhoneFrameFullWhite.png";

const PhoneFramedContent = ({
  backgroundStyle,
  className,
  frameType = "full",
  mediaAttributes,
  posterAttributes,
}) => {
  if (!mediaAttributes) {
    return null;
  }

  const frameImage = {
    "light-cutOff": iPhoneFrameWhiteCutOff,
    "light-full": iPhoneFrameWhiteFull,
    "medium-cutOff": iPhoneFrameMediumCutOff,
    "medium-full": iPhoneFrameMediumFull,
    "dark-cutOff": iPhoneFrameBlackCutOff,
    "dark-full": iPhoneFrameBlackFull,
  }[`${backgroundStyle}-${frameType}`];

  if (!frameImage) {
    return null;
  }

  const elements = [];

  // Image
  if (mediaAttributes.mime.startsWith("image")) {
    elements.push(
      <div
        className={styles.image}
        key="img"
        style={{ backgroundImage: `url(${mediaAttributes.url})` }}
      />
    );
  }

  if (mediaAttributes.mime.startsWith("video")) {
    elements.push(
      <video
        className={styles.video}
        key="video"
        poster={posterAttributes?.url}
        muted
        loop
        autoPlay
        playsInline
      >
        <source src={mediaAttributes.url} type={mediaAttributes.mime} />
      </video>
    );
  }

  return (
    <div
      className={cx(
        styles.phoneContainer,
        frameType === "cutOff" ? styles.cutOff : styles.full,
        className
      )}
    >
      <div className={styles.innerContainer}>{elements}</div>
      <img
        alt="Just your friendly neighbourhood iPhone"
        className={styles.outerFrame}
        src={frameImage}
      />
    </div>
  );
};

export default PhoneFramedContent;
