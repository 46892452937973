import React from "react";

import cx from "classnames";

const CtaContainer = ({ className, data, foregroundColor = "dark" }) => {
  if (!data.ctas || data.ctas.length === 0) {
    return null;
  }

  return (
    <div
      className={cx(
        "d-flex flex-row justify-content-center justify-content-lg-start",
        data.ctasDisplayOn === "desktop" && "d-none d-lg-flex",
        data.ctasDisplayOn === "mobile" && "d-lg-none",
        className
      )}
    >
      {data.ctas.map((cta, idx) => (
        <a
          href={cta.url}
          key={idx}
          className={cx(
            "btn btn-md",
            cta.style === "Solid"
              ? `btn-${foregroundColor}`
              : `btn-outline-${foregroundColor}`,
            idx > 0 && "ms-2"
          )}
        >
          {cta.text}
        </a>
      ))}
    </div>
  );
};

export default CtaContainer;
