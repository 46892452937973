import React, { useMemo } from "react";
import { Container, Row, Col, Ratio } from "react-bootstrap";
import * as styles from "./Grid.module.scss";
import _ from "lodash";

/**
 * Grid block
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const Grid = ({ data }) => {
  let containerColor = "dark";
  let textColor = "light";

  if ("Light" === data.backgroundColor) {
    containerColor = "light";
    textColor = "dark";
  }

  const pages = useMemo(
    () => [...(data.pages.data || [])].reverse(),
    [data.pages.data]
  );

  return (
    <article className={`${styles.wrapper} bg-${containerColor}`}>
      <Container>
        <Row>
          {pages.map((page, index) => {
            return (
              <Col xs={12} sm={6} lg={4} key={index}>
                <a href={`/${page.attributes.slug}`} aria-label={`View page`}>
                  <Ratio aspectRatio={`16x9`}>
                    <div
                      className={styles.thumb}
                      style={{
                        backgroundImage: `url(${_.get(
                          page,
                          "attributes.featured.data.attributes.url"
                        )})`,
                      }}
                    />
                  </Ratio>
                  <h2 className={`text-${textColor} h3 mt-3 mb-4`}>
                    {page.attributes.title}
                  </h2>
                </a>
              </Col>
            );
          })}
        </Row>
      </Container>
    </article>
  );
};

export default Grid;
