import React from "react";
import ReactMarkdown from "react-markdown";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./Text.module.scss";

/**
 * Text block
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const Text = ({ data }) => {
  let containerColor = "dark";
  let textColor = "light";

  if ("Light" === data.backgroundColor) {
    containerColor = "light";
    textColor = "dark";
  }

  return (
    <article className={`${styles.wrapper} bg-${containerColor} text-center`}>
      <Container>
        <Row className="justify-content-lg-center">
          <Col lg={10} xl={8}>
            <h2 className={`h3 text-${textColor}`}>{data.title}</h2>
            {data.body ? (
              <ReactMarkdown className={`text-${textColor}`}>
                {data.body}
              </ReactMarkdown>
            ) : null}
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default Text;
