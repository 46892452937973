import React from "react";
import ReactMarkdown from "react-markdown";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./TextImage.module.scss";
import cx from "classnames";

/**
 * TextImage block
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const TextImage = ({ data }) => {
  let containerColor = "dark";
  let textColor = "light";

  if ("Light" === data.backgroundColor) {
    containerColor = "light";
    textColor = "dark";
  }

  let imageColOrder = "order-2 order-lg-1";
  let textColOrder = "order-1 order-lg-2";

  if (data.reorder) {
    imageColOrder = "order-2";
    textColOrder = "order-1";
  }

  return (
    <article
      className={`${styles.wrapper} bg-${containerColor} text-center text-lg-start`}
    >
      <Container>
        <Row className="g-0 align-items-lg-center">
          <Col xs={12} lg={6} className={cx(imageColOrder, "pb-4 pb-lg-0")}>
            {data.imageMobile?.data && (
              <figure className="mb-0 d-lg-none">
                <img
                  alt={data.imageMobile.data.attributes.alternativeText}
                  src={data.imageMobile.data.attributes.url}
                  className="img-fluid w-100"
                />
              </figure>
            )}
            {data.imageDesktop?.data && (
              <figure className="mb-0 d-none d-lg-block">
                <img
                  alt={data.imageDesktop.data.attributes.alternativeText}
                  src={data.imageDesktop.data.attributes.url}
                  className="img-fluid w-100"
                />
              </figure>
            )}
          </Col>
          <Col xs={12} lg={6} className={`${textColOrder}`}>
            <div className={styles.text}>
              <h2 className={`${styles.title} h3 text-${textColor}`}>
                {data.title}{" "}
                <span className={`${styles.title__border} bg-${textColor}`} />
              </h2>
              {data.body ? (
                <ReactMarkdown
                  className={`${styles.paragraph} text-${textColor}`}
                >
                  {data.body}
                </ReactMarkdown>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default TextImage;
